var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{staticClass:"py-8",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('div',[_c('h1',{staticClass:"text-3xl font-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.resetErrors(); _vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_c('div',{staticClass:"grid lg:grid-cols-12 gap-4 mt-8"},[_c('ValidationProvider',{staticClass:"lg:col-span-12",attrs:{"name":"name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-select',{attrs:{"label":"Tipo","invalid-message":_vm.form.errors.type || errors[0]},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('cv-select-option',{attrs:{"selected":"","value":"radiography"}},[_vm._v(" Radiografia ")]),_c('cv-select-option',{attrs:{"value":"report"}},[_vm._v(" Relatório ")]),_c('cv-select-option',{attrs:{"value":"trace"}},[_vm._v(" Ficha de Rastreabilidade ")]),_c('cv-select-option',{attrs:{"value":"document"}},[_vm._v(" Documento ")]),_c('cv-select-option',{attrs:{"value":"other"}},[_vm._v(" Outro ")])],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-12",attrs:{"name":"name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Nome","invalid-message":_vm.form.errors.name || errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-12",attrs:{"tag":"div","rules":"required","name":"uploader"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FileUploader',{attrs:{"accepted-formats":['image/*']},model:{value:(_vm.form.attachment),callback:function ($$v) {_vm.$set(_vm.form, "attachment", $$v)},expression:"form.attachment"}}),(errors.length)?_c('div',{staticClass:"mb-3 text-red-600"},[_vm._v(" Selecione um arquivo ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-12",attrs:{"tag":"div","rules":"required","name":"obtained at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateInput',{attrs:{"label":"Data de Obtenção","invalid-message":_vm.form.errors.obtainedAt || errors[0]},model:{value:(_vm.form.obtainedAt),callback:function ($$v) {_vm.$set(_vm.form, "obtainedAt", $$v)},expression:"form.obtainedAt"}}),(errors.length)?_c('div',{staticClass:"mb-3 text-red-600"},[_vm._v(" Selecione um arquivo ")]):_vm._e()]}}],null,true)}),_c('cv-text-area',{staticClass:"lg:col-span-12",attrs:{"label":"Descrição (opcional)","rows":"5"},model:{value:(_vm.form.notes),callback:function ($$v) {_vm.$set(_vm.form, "notes", $$v)},expression:"form.notes"}})],1),_c('div',{staticClass:"w-full lg:w-2/3"},[_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}})],1),(_vm.done)?_c('cv-button',{staticClass:"whitespace-no-wrap mt-1",attrs:{"type":"submit"}},[_vm._v(" Enviar ")]):_c('cv-button-skeleton')],1)]),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }