




































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import isEmpty from 'lodash/isEmpty'
import snakeCaseKeys from 'snakecase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    TablePagination,
    TrashCan16,
    GenericModalDelete
  }
})
export default class MedicalPatientDocumentsTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) patientId!: string
  totalDocuments = 0
  documents: Array<any> = []
  deleteModalIsOpened = false
  selectedDocument: any = {}

  fetchDocuments (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })

    axios.get(`/patient/${this.patientId}/attachment`, {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.documents = camelCaseKeys(response.data.data, { deep: true })
        this.totalDocuments = response.data.total
      })
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'O Documento foi removido com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetchDocuments()
  }

  get deleteUrl () {
    return isEmpty(this.selectedDocument) ? '' : `/patient/${this.patientId}/attachment/${this.selectedDocument.id}`
  }

  onPageChange (pagination: Pagination) {
    this.fetchDocuments(pagination.page, pagination.length)
  }
}
