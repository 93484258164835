































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import moment from 'moment'

import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import FileUploader from '@/partials/forms/components/FileUploader.vue'
import DateInput from '@/partials/forms/components/DateTime/DateSimple.vue'

@Component({
  directives: { mask },
  components: {
    DateInput,
    FileUploader,
    PasswordConfirmField,
    NotifyOnFailed,
    ValidationProvider,
    ValidationObserver
  }
})
export default class EventActivityAttachmentForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) patientId!: string

  done = true

  form: Record<string, any> = {
    name: '',
    patientId: '',
    type: 'radiography',
    notes: '',
    obtainedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
    attachment: '',
    confirm: '',
    errors: {
      name: '',
      type: '',
      notes: '',
      obtainedAt: '',
      attachment: '',
      confirm: ''
    }
  }

  created () {
    this.form.patientId = this.patientId
    if (process.env.NODE_ENV === 'development') {
      Object.assign(this.form, {
        name: 'Material ABC',
        obtainedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        notes: 'my notes'
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      name: '',
      type: '',
      notes: '',
      obtainedAt: '',
      attachment: '',
      confirm: ''
    }
  }

  submit () {
    this.done = false
    const verb = 'post'
    const route = `/patient/${this.patientId}/attachment`

    axios[verb](route, snakeCaseKeys(this.form))
      .then(() => {
        this.redirectOnSuccess(this.form.name)
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  redirectOnSuccess (activityName: string) {
    const successVerb = 'cadastrado'

    const flashMessage = {
      message: `O Documento ${activityName} foi ${successVerb} com sucesso.`,
      kind: 'success'
    }

    this.$router.push({ name: 'MedicalPatientDocuments', params: { patientId: this.patientId } }, () => this.setFlashMessage(flashMessage))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }
}
