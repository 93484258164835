






import { Component, Vue } from 'vue-property-decorator'

import DocumentForm from '@/partials/forms/Medical/Patient/DocumentForm.vue'

@Component({
  components: {
    DocumentForm
  }
})
export default class MedicalPatientDocumentCreate extends Vue {

}
