






import { Component, Vue } from 'vue-property-decorator'

import PatientDocumentsTable from '@/partials/tables/Medical/Patients/Documents.vue'

@Component({
  components: {
    PatientDocumentsTable
  }
})
export default class MedicalPatientDocuments extends Vue {

}
